<template>
	<div class="gachi gachi-signup" id="gachi" :class="{ 'gachi-login': isMobile }">
		<!-- begin::gachi-header -->
		<login-header />
		<!-- end::gachi-header -->
		<router-view />
		<!-- begin::gachi-footer -->
		<main-footer />
		<!-- end::gachi-footer -->

		<common-alert></common-alert>
		<address-modal />
	</div>
</template>
<!--<style lang="scss" scoped>-->
<!--@import "../../assets/css/common.css";-->
<!--@import "../../assets/css/page.popup.css";-->
<!--@import "../../assets/css/page.auth.css";-->
<!--</style>-->
<script>
import LoginHeader from './header/LoginHeader';
import MainFooter from './footer/MainFooter';
import CommonAlert from '../../components/common/CommonAlert';
import { mapGetters } from 'vuex';
import AddressModal from '../../components/AddressModal';

export default {
	name: 'LoginLayout',
	components: {
		LoginHeader,
		MainFooter,
		CommonAlert,
		AddressModal,
	},
	computed: {
		...mapGetters('common', ['isMobile', 'address']),
	},
};
</script>
