<template>
    <!-- begin::gachi-header -->
  <header class="gachi-header is-sticky" id="gachi-header" data-header="min">
    <div class="header-component">
      <div class="header-gnb">
        <div class="header-logo">
          <router-link
              custom
              to="/"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a :href="href" class="logo-link" @click="navigate">
              <span class="logo-gachi">가치삽시다</span>
            </a>
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'LoginHeader'
}
</script>